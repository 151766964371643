import styled from "styled-components";
import Disconnected from "../../assets/pages/Home/Disconnected.svg";

const OnlineStatusImage = styled.img`
  /* position: absolute; */
  width: 24px;
  height: 24px;
  z-index: 100;
`;

export const IsOffline = ({ isOffline }: any) => {
  return (
    <div className="group w-6 h-6 relative">
      {isOffline === true && (
        <>
          <OnlineStatusImage src={Disconnected} />
          <div
            className="hidden group-hover:block absolute px-3 py-2 text-sm whitespace-nowrap shadow rounded-lg font-bold bottom-[24px]"
            style={{ top: "-32px", left: "0px", backdropFilter: "blur(8px)", background: "#ffffff30", color: "#EF4337", zIndex: 999 }}
          >
            Disconnected
          </div>
        </>
      )}
    </div>
  );
};
