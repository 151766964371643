import styled from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 100vh;
  width: 100vw;

  @media screen and (max-width: 768px) {
    height: 100vh;
  }
`;

export const BG = styled.img`
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  min-height: 10rem;
  @media screen and (max-width: 768px) {
    max-width: 60%;
  }
`;

export const Logo = styled.img`
  /* position: absolute; */
  /* top: 8rem; */
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 160px;
  @media screen and (max-width: 768px) {
    width: 160px;
    top: 13rem;
  }
`;

export const WelComeText = styled.p`
  position: absolute;
  top: 16rem;
  text-align: center;
  justify-content: center;
  justify-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 25px;

  color: #919298;

  @media screen and (max-width: 768px) {
    max-width: 80%;
  }
`;

export const LabelUser = styled.label`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  top: 20rem;
  font-size: 20px;
  color: #989c9c;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 100%;

    padding: 0px 40px;
  }
`;

export const LabelPassword = styled.label`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 0;
  margin: 0;
  top: 26rem;
  font-size: 20px;
  color: #989c9c;
  color: #989c9c;
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0px 40px;
  }
`;

export const InputUser = styled.input`
  /* position: absolute; */
  padding: 0px 12px;
  top: 5rem;
  width: 100%;
  height: 35px;
  background: #e5e5e5;
  border-radius: 10px;
  border-style: none;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const InputPassword = styled.input`
  /* position: absolute; */
  padding: 0px 12px;
  top: 5rem;
  width: 100%;
  height: 35px;
  background: #e5e5e5;
  border-radius: 10px;
  border-style: none;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const SubmitBtn = styled.button`
  padding: 6px 12px;
  position: absolute;
  width: 40%;
  height: 35px;
  border-radius: 10px;
  border-style: none;
  top: 35rem;
  background: #51cbce;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;

  @media screen and (max-width: 768px) {
    width: 80%;
    /* max-width: 80%; */
  }
`;

export const Form = styled.form`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px;
  height: 50px;
  @media screen and (max-width: 1100px) {
    width: 100px;
  }
`;

export const FormContainer = styled.div`
  width: 50%;
  padding: 10px 100px;
  margin-top: 20px;
  @media only screen and (max-width: 480px) {
    margin-top: 30px;
    width: 95%;
    padding: 10px 30px;
  }
`;
