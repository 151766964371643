import { useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { FirebaseContext } from "../firebase";
import { Wrapper, CardBody, ContainerBody, WrapperBody, AutomationHeader, AdvancedSettingButton, ReportHeader } from "components/Home/Home.elements";
import Sidebar from "components/Sidebar";
import "antd/dist/antd.min.css";
import { Spin } from "antd";
import { mapValueToKey } from "utils/mapValueToKey";
import UserService from "services/user.services";
import { useToasts } from "react-toast-notifications";
import { useEffect } from "react";
import { RoomStatusBlock } from "components/Home/RoomStatusBlock";
import { DeviceDataProps, DevicesDataProps } from "components/Home/homeType";
import { AdvancedSetting } from "components/Home/AdvancedSetting";
import { Automation } from "components/Home/Automation";
import { Report } from "components/Home/Report";
import { useDisclosure } from "utils/hooks";

export const Row = styled.div`
  margin: 5px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
`;

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export type GraphDataProps = {
  [name: string]: {
    title: string;
    data: { x: any; y: any }[];
    unit: string;
  };
};

export const hospitalMapper: any = {
  sfhadmin: "saraburi_field_hospital",
  sfhadmin2: "saraburi2_field_hospital",
  cufhadmin: "chula_field_hospital",
  rajavithiadmin: "rajavithi_field_hospital",
  sirirajadmin: "siriraj_field_hospital",
  siriraj2admin: "siriraj2_field_hospital",
  abhfhadmin: "abhaibhubejhr_field_hospital",
};

const roomsInFirstFloor = [
  158,
  159,
  160,
  161,
  162,
  163
]

const roomsInSecondFloor = [
  166,
  167,
  168,
  169,
  170,
  171,
  172
]

const Home = ({ setToken }: any) => {
  const [tabs, setTabs] = useState("dashboard");
  const firebase = useContext<any>(FirebaseContext);
  const [deviceDataByRoom, setDeviceDataByRoom] = useState<DevicesDataProps | undefined>();
  const [allDevices, setAllDevices] = useState<DeviceDataProps | undefined>();
  const [roomList, setRoomList] = useState<any[]>([]);
  const [selectedRoomNo, setSelectedRoomNo] = useState<string[]>([]);
  const [mappedDeviceData, setMappedDeviceData] = useState<{ [room: string]: DeviceDataProps }>();
  const [advancedModalIsOpen, setAdvancedModalIsOpen] = useState<boolean>(false);
  const [noOfSubdevByRoom, setNoOfSubdevByRoom] = useState<{ [room: string]: number }>();
  const { addToast } = useToasts();
  const [userName, setUserName] = useState();
  const roomDialog = useDisclosure();
  const [deviceByFloor, setDeviceByFloor] = useState<{ [floorName: string]: number[] }>({}); // {floor: [room1, room2, ...]}

  const extractRoomDataOfAllDevices = (data: any): any => {
    if (!data) return [];
    let roomListData: any = [];
    let devicesByRoomData: { [roomName: string]: any } = {};
    let noOfSubdevByRoom: { [roomName: string]: number } = {};
    for (let i = 0; i < data.length; i++) {
      const room_name = data[i]["room"];
      const noOfSubdev = data[i]["subdevices"] ? Object.keys(data[i]["subdevices"]).length : 0;
      if (!roomListData.includes(room_name)) {
        roomListData.push(room_name);
        devicesByRoomData[room_name] = [data[i]];
        noOfSubdevByRoom[room_name] = noOfSubdev;
      } else {
        devicesByRoomData[room_name].push(data[i]);
        noOfSubdevByRoom[room_name] += noOfSubdev;
      }
    }
    return { roomListData, devicesByRoomData, noOfSubdevByRoom };
  };

  const fetchDeviceDataByRoom = async () => {
    try {
      const res = await UserService.getDevices();
      const allDevicesData = res.data.devices;
      const roomByFloor = {
        1: allDevicesData.filter((device: any) => roomsInFirstFloor.includes(device.room)),
        2: allDevicesData.filter((device: any) => roomsInSecondFloor.includes(device.room))
      }
      setDeviceByFloor(roomByFloor)
      const extractedData = extractRoomDataOfAllDevices(allDevicesData)
      const roomList = extractedData?.["roomListData"];
      const deviceListByRoom = extractedData?.["devicesByRoomData"];
      const subdevByRoom = extractedData?.["noOfSubdevByRoom"];
      setDeviceDataByRoom(deviceListByRoom);
      setAllDevices(allDevicesData);
      setRoomList(roomList);
      setNoOfSubdevByRoom(subdevByRoom)
      setMappedDeviceData(mapValueToKey(allDevicesData, "device_id"));
    } catch (err) {
      addToast("Cannot fetch data from the server.", {
        appearance: "error",
        autoDismiss: true,
      });
      console.log(err);
    }
  };

  const handleOnOpen = (roomName: string) => {
    setSelectedRoomNo([roomName]);
    roomDialog.onOpen();
  };

  useMemo(() => {
    let username: any = sessionStorage.getItem("username");
    setUserName(username);
  }, [firebase]);

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefreshed");
    if (isRefreshed !== "true") {
      sessionStorage.setItem("isRefreshed", "true");
      window.location.reload();
    }
    fetchDeviceDataByRoom();
  }, []);

  return (
    <>
      <Wrapper>
        <CardBody>
          <Sidebar setToken={setToken} setTabs={setTabs} tabs={tabs} roomDialog={roomDialog} />
          <ContainerBody style={{ paddingTop: "30px", overflow: "scroll" }}>
            {!deviceDataByRoom ? (
              <>
                <SpinnerContainer>
                  <Spin tip="Loading..." style={{ marginTop: "100px", fontSize: "22px", color: "#0c4271" }} />
                </SpinnerContainer>
              </>
            ) : (
              <>
                {tabs === "dashboard" && allDevices && (
                  <WrapperBody style={{ overflowY: "scroll" }}>
                    <RoomStatusBlock
                      deviceDataByRoom={deviceDataByRoom}
                      noOfSubdevByRoom={noOfSubdevByRoom}
                      allDevices={allDevices}
                      roomList={roomList}
                      roomDialog={roomDialog}
                      selectedRoomNo={selectedRoomNo}
                      handleOnOpen={handleOnOpen}
                      deviceByFloor={deviceByFloor}
                    />
                  </WrapperBody>
                )}
                {/* {tabs === "automation" && (
                  <>
                    <AutomationHeader>
                      Automation
                      <AdvancedSettingButton onClick={() => setAdvancedModalIsOpen(!advancedModalIsOpen)}>Advanced setting</AdvancedSettingButton>
                    </AutomationHeader>
                    <Automation mappedDeviceData={mappedDeviceData} />
                    <AdvancedSetting advancedModalIsOpen={advancedModalIsOpen} closeAdvancedModal={closeAdvancedModal} />
                  </>
                )} */}
                {tabs === "report" && (
                  <>
                    <ReportHeader>Report</ReportHeader>
                    <Report />
                  </>
                )}
              </>
            )}
          </ContainerBody>
        </CardBody>
      </Wrapper>
    </>
  );
};
export { Home };
