import { useFirebase } from "utils/hooks/useFirebase";
import Config from "../../configs/room_config.json";
import ReturnIcon from "../../assets/pages/Home/ReturnIcon.svg";
import BulbOn from "../../assets/pages/Home/BulbOn.png";
import BulbOff from "../../assets/pages/Home/BulbOff.png";
import PopoverButton from "components/PopoverButton/PopoverButton";
import UserServices from "services/user.services";
import { Spin } from "antd";
import { useToasts } from "react-toast-notifications";
import { IsOffline } from "components/IsOffline/IsOffline";
import styled from "styled-components";
import { lazy, useState } from "react";

const Button = styled.button`
  height: 40px;
  box-shadow: "1px 3px 20px rgba(196, 196, 196, 0.3)";
  backdrop-filter: blur(20px);
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  padding: 0px 20px;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(331.89deg, #397cdd -52.72%, #93b4f9 406.63%);
  color: white;
  font-size: 18px;
  margin-top: -6px;
  :hover {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
  }
  :disabled {
    background: #cbd5e0;
    cursor: not-allowed;
    box-shadow: none;
    filter: none;
  }
`;

const OffButton = styled.button`
  height: 40px;
  box-shadow: "1px 3px 20px rgba(196, 196, 196, 0.3)";
  backdrop-filter: blur(20px);
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  padding: 0px 20px;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-top: -6px;
  :hover {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
  }
  background: #cbd5e0;
  :disabled {
    background: #cbd5e0;
    cursor: not-allowed;
    box-shadow: none;
    filter: none;
  }
`

const RoomStatusNumber: React.FC<{
  online: number;
  total: number;
}> = ({ online, total }) => {
  return (
    <div className={`w-12 rounded-lg ${online === 0 ? "bg-slate-300" : "bg-green-200"} flex justify-center items-center relative text-[16px]`}>
      <span className="font-bold">{online}</span>/{total}
      <span className="text-[10px] absolute " style={{ bottom: "-16px" }}>
        ON/OFF
      </span>
    </div>
  );
};

const DeviceStatusBlock: React.FC<{
  device: any[];
  total: number;
}> = ({ device, total }) => {
  const firebaseData = useFirebase({ deviceList: device });

  const countOnline = (deviceObject: any): any => {
    if (Object.keys(deviceObject).length === 0 || !deviceObject) return 0;
    let online = 0;
    let total = 0;
    const deviceList = Object.keys(deviceObject);
    deviceList.forEach((deviceId: any) => {
      if (deviceObject?.[deviceId]?.relay) {
        const subdevList = Object.keys(deviceObject?.[deviceId]?.relay);
        if (!subdevList || subdevList.length === 0) return 0;
        subdevList.forEach((subdev: any) => {
          total++;
          if (deviceObject?.[deviceId]?.relay?.[subdev]?.state === "on") {
            online++;
          }
        });
      }
    });
    return { online, total };
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full flex justify-between">
        <p className="text-[16px]">Switch</p>
        {/* <RoomStatusNumber online={countOnline(firebaseData["data"])["online"]} total={countOnline(firebaseData["data"])["total"]} /> */}
        <RoomStatusNumber online={countOnline(firebaseData["data"])["online"]} total={total} />
      </div>
    </div>
  );
};

export const RoomStatusBlock = ({ allDevices, deviceDataByRoom, roomList, selectedRoomNo, roomDialog, handleOnOpen, noOfSubdevByRoom, deviceByFloor }: any) => {
  const RoomConfig = Config as any;
  const fbData = useFirebase({ deviceList: allDevices });
  const { addToast } = useToasts();
  const [loading, setLoading] = useState({
    1: false,
    2: false,
  });

  const sleep2Sec = (floor: string) => {
    setLoading((prev) => ({ ...prev, [floor]: true }));
    setTimeout(() => {
      setLoading((prev) => ({ ...prev, [floor]: false }));
    }, 3000);
  };

  const handleChangeStatusAll = async (floor: string, status: 'on' | 'off') => {
    sleep2Sec(floor)
    try {
      const deviceList = deviceByFloor[floor];
      await Promise.all(
        deviceList.map(async (device: any) => {
          const subdevList = Object.values(device.subdevices);
          if (!subdevList || subdevList.length === 0) return 0;
          subdevList.forEach(async (subdev: any) => {
            const subdevName = subdev.nickname
            await UserServices.postDeviceControl(device["device_id"], subdevName, status);
          }
          );
        })
      )
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleChangeStatus = async (deviceId: string, subdev: string, state: string) => {
    try {
      fbData.setData((prev: any) => {
        let temp = { ...prev };
        if (!temp[deviceId]["relay"]) {
          addToast("Unable to control this device", { appearance: "error", autoDismiss: true });
          return temp;
        }
        temp[deviceId]["relay"][subdev]["loading"] = true;
        temp[deviceId]["relay"][subdev]["state"] = state;
        return temp;
      });
      await UserServices.postDeviceControl(deviceId, subdev, state);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!roomDialog.isOpen ? (
        <>
          <h1 className="text-[32px] font-bold mb-6">LORPHOONPHOL RICE MILL</h1>
          <div className="flex gap-x-4">
            <h2 className="text-[20px] mb-3 font-bold">1st Floor</h2>
            <Button
              onClick={() => handleChangeStatusAll('1', 'on')}
              disabled={loading['1']}
            >
              ON
              <img src={BulbOn} className="w-8" />
            </Button>
            <OffButton
              style={{ color: 'black' }}
              onClick={() => handleChangeStatusAll('1', 'off')}
              disabled={loading['1']}
            >
              OFF
              <img src={BulbOff} className="w-8" />
            </OffButton>
            {
              loading['1'] && <Spin style={{ marginLeft: "4px" }} />
            }
          </div>
          <div className="grid grid-cols-12 w-full gap-4 mb-8">
            {roomList &&
              deviceDataByRoom &&
              roomList
                .filter((name: string) => RoomConfig?.[name]?.["floor_name"] === "1st Floor")
                .sort(function (a: any, b: any) {
                  if (a < b) {
                    return -1;
                  }
                  if (a > b) {
                    return 1;
                  }
                  return 0;
                })
                .map((roomName: string) => {
                  return (
                    <div
                      key={roomName}
                      className="h-28 col-span-3 flex flex-col justify-between bg-slate-50 shadow-lg rounded-lg p-3 pb-5 cursor-pointer"
                      onClick={() => handleOnOpen(roomName)}
                    >
                      <div className="w-full flex justify-between">
                        <h2 className="text-[17px] font-bold leading-[1.2rem]">{RoomConfig[roomName]["room_name"]}</h2>
                      </div>
                      {deviceDataByRoom[roomName] && <DeviceStatusBlock device={deviceDataByRoom?.[roomName]} total={noOfSubdevByRoom?.[roomName]} />}
                    </div>
                  );
                })}
          </div>
          <div className="flex gap-x-4">
            <h2 className="text-[20px] mb-3 font-bold">2nd Floor</h2>
            <Button onClick={() => handleChangeStatusAll('2', 'on')} disabled={loading['2']}>
              ON
              <img src={BulbOn} className="w-8" />
            </Button>
            <OffButton style={{ color: 'black' }} onClick={() => handleChangeStatusAll('2', 'off')} disabled={loading['2']}>
              OFF
              <img src={BulbOff} className="w-8" />

            </OffButton>
            {
              loading['2'] && <Spin style={{ marginLeft: "4px" }} />
            }
          </div>
          <div className="grid grid-cols-12 w-full gap-4">
            {roomList &&
              deviceDataByRoom &&
              roomList
                .filter((name: string) => RoomConfig?.[name]?.["floor_name"] === "2nd Floor")
                .sort(function (a: any, b: any) {
                  if (a < b) {
                    return -1;
                  }
                  if (a > b) {
                    return 1;
                  }
                  return 0;
                })
                .map((roomName: string) => {
                  return (
                    <div
                      key={roomName}
                      className="h-28 col-span-3 flex flex-col justify-between bg-slate-50 shadow-lg rounded-lg p-3 pb-5 cursor-pointer"
                      onClick={() => handleOnOpen(roomName)}
                    >
                      <div className="w-full flex justify-between">
                        <h2 className="text-[16px] font-bold leading-[1.2rem]">{RoomConfig[roomName]["room_name"]}</h2>
                      </div>
                      {deviceDataByRoom[roomName] && <DeviceStatusBlock device={deviceDataByRoom?.[roomName]} total={noOfSubdevByRoom?.[roomName]} />}
                    </div>
                  );
                })}
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex justify-between">
            <h1 className="text-[32px] font-bold mb-0">{RoomConfig?.[selectedRoomNo[0]]?.room_name}</h1>
            <div className="flex justify-center items-center cursor-pointer text-lg" onClick={() => roomDialog.onClose()}>
              <img src={ReturnIcon} /> BACK
            </div>
          </div>
          <div className="grid grid-cols-12 w-full gap-4">
            {deviceDataByRoom &&
              deviceDataByRoom[selectedRoomNo[0]] &&
              deviceDataByRoom[selectedRoomNo[0]]
                .sort(function (a: any, b: any) {
                  let x = a.device_name.toLowerCase();
                  let y = b.device_name.toLowerCase();
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                })
                .map((device: any) => {
                  return (
                    <div key={device.id} className="h-[fit-content] col-span-12 flex flex-col justify-between py-4 pb-6 border-b-2 border-gray-200">
                      <div className="relative flex justify-start items-center mb-2 w-[fit-content]">
                        <h2 className="text-2xl font-bold whitespace-nowrap">{device.device_name}</h2>
                        <PopoverButton deviceId={device.device_id} />
                        <IsOffline isOffline={fbData["data"][device.device_id]?.device?.["subdev_0"]?.device_status !== "online"} />
                      </div>
                      <div className="grid grid-cols-12 gap-x-4">
                        {device.subdevices &&
                          [
                            { id: "0", name: "subdev_0", label: "Switch 1" },
                            { id: "1", name: "subdev_1", label: "Switch 2" },
                            { id: "2", name: "subdev_2", label: "Switch 3" },
                          ]
                            .filter((subdev_data: any) => device.subdevices?.[subdev_data["id"]])
                            .map((subdev_data: any) => {
                              const state = fbData["data"][device.device_id]?.relay?.[subdev_data["name"]]?.state;
                              return (
                                <>
                                  <div
                                    className="bg-slate-50 rounded-lg col-span-3 flex flex-col justify-center items-center p-2 cursor-pointer"
                                    onClick={() => handleChangeStatus(device.device_id, subdev_data["name"], state === "on" ? "off" : "on")}
                                  >
                                    <div className="relative">
                                      <h2 className="text-xl font-bold mb-2">{subdev_data["label"]}</h2>
                                    </div>
                                    <img src={state === "on" ? BulbOn : BulbOff} className="w-20" />
                                    <p className={`text-lg font-bold ${state === "on" ? "text-emerald-500" : "text-gray-500"}`}>
                                      {fbData["data"][device.device_id]?.relay?.[subdev_data["name"]]?.["loading"] && (
                                        <Spin style={{ marginRight: "4px" }} />
                                      )}
                                      {state === "on" ? "ON" : state === undefined ? "-" : "OFF"}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                      </div>
                    </div>
                  );
                })}
          </div>
        </>
      )}
    </>
  );
};
