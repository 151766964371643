import axios from "axios";
import { API_CONSTANTS } from "./api.constants";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BASE_URL;

class UserService {
  getDevices() {
    return axios.get(API_CONSTANTS.DEVICES, {
      headers: authHeader(),
    });
  }

  getDeviceHistory(starttime: any, endtime: any, device_id: any, subdevice_idx: any, device_activity: any, sample_min: any) {
    let url: string = `?RequestId=123&starttime=${starttime}&endtime=${endtime}&device_id=${device_id}&subdevice_idx=${subdevice_idx}&device_activity=${device_activity}&sample_min=${sample_min}`;
    return axios.get(API_URL + "api/v2.0/historydevice" + url, {
      headers: authHeader(),
    });
  }

  postAutomations(
    allowNotification: boolean,
    roomName: string,
    subDeviceName: string,
    parameter: string,
    comparator: string,
    value: string | number,
    active: boolean
  ) {
    let payload: any = {
      automation_image: "",
      automation_name: "automation_daikin",
      trigger: {
        trigger_device: {
          [roomName]: {
            [subDeviceName]: {
              [parameter]: {
                [comparator]: value,
              },
            },
          },
        },
      },
      condition: {
        condition_value: "",
        condition_event: "",
      },
      action: {},
      allow_notification: allowNotification ? active : false,
      notification_message: `${parameter} is higher than limit`,
    };

    return axios.post(
      API_URL + "api/v2.0/automations/",
      {
        headers: authHeader(),
      },
      payload
    );
  }

  getDeviceByFloor(floorId: number) {
    return axios.get(API_CONSTANTS.FLOOR, {
      headers: authHeader(),
      params: { floor_id: floorId },
    });
  };

  postDeviceControl(deviceId: string, subDeviceName: string, state: string) {
    let payload: any = {
      RequestId: "12345",
      action: {
        [deviceId]: { [subDeviceName]: { command: { state: state } } }
      },
      topic: "devicecontrol"
    }
    return axios({
      "method": "POST",
      "url": API_CONSTANTS.DEVICES_CONTROL_URL,
      "headers": authHeader(),
      "data": payload,
    })
  }

}

export default new UserService();
