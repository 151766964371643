import axios from "axios";
import { API_CONSTANTS } from "./api.constants";
// import authHeader from './auth-header';

class AuthService {
  login(username: string, password: string) {
    console.log('LOGIN')
    return axios.post(API_CONSTANTS.LOGIN_URL, {
      username,
      password,
    });
  }
}

export default new AuthService();
