import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import BgImageHeader from 'assets/pages/Home/room_bg.jpg';

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const ContainerBody = styled.div`
  width: calc(100% - 260px);
  height: 100vh;
  padding: 25px 25px 25px 0px;
  @media only screen and (max-width: 1201px) {
    width: calc(100% - 220px);
    padding: 25px 15px 25px 0px;
  }
`;

export const WrapperBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: rgba(249, 249, 249, 0.7);
  box-shadow: 3px 10px 30px rgba(16, 118, 186, 0.1), 2px 4px 30px rgba(194, 194, 194, 0.1);
  border-radius: 40px;
  background-color: white;
  padding: 24px 24px;
`

export const Logo = styled.img`
  /* top: 100px; */
  position: absolute;
  top: 35px;
  display: flex;
  justify-content: center;
  justify-items: center;
  /* width: 80%; */
  @media screen and (max-width: 768px) {
    top: 55px;
    max-width: 60%;
    max-height: 6em;
  }
`;

export const TextContrainer = styled.div`
  padding: 10px;
  margin: 0px 0px;
  color: #919298;
  margin-top: 8em;
  /* left: 10px; */
  @media screen and (max-width: 768px) {
    max-width: 80%;
    margin-top: 2em;
    margin-bottom: 10px;
  }
`;

export const BoxBg = styled.div`
  margin: 20px 20px 20px 20px;
  background-color: #92d8ce;
  height: auto;
  width: 515px;
  padding: 5px 5px 10px 5px;
  border-radius: 10px;

  /* align-items: center;
  text-align: center;

  display: flex;
  justify-content: center; */
  /* justify-items: center; */

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const LightCon = styled.div`
  width: auto;
  height: 110px;
  background: ${({ click }) => (click ? '#FFD600' : '#ffffff')};
  border: 1px solid #919298;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #919298;
`;

export const AirCon = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 15px;
  padding: 5px 20px 20px 20px;
  box-sizing: border-box;
  box-shadow: 10px 14px 14px rgb(0 0 0 / 3%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #919298;
`;

export const Row = styled.div`
  margin: 5px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  flex: ${(props) => props.size};
  margin: 3px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 100%;
  /* background-color: white; */
`;

export const CardHeader = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: url(${BgImageHeader}) no-repeat center;
  background-size: cover;
`;

export const ACIcon = styled.img`
  height: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const BodyContainer = styled.div`
  width: 100%;
  padding: 0px 140px;
  height: fit-content;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  @media only screen and (max-width: 1201px) {
    padding: 0px 50px;
  }
`;

export const AutomationContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 10px 40px;
  flex-direction: column;
`;

export const AutomationSubContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid #aeaeae;
`;

export const AutomationColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AutomationTopic = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
`;

export const Name = styled.h4`
  font-size: 15px;
`;

export const AdvancedSettingHead = styled.h2`
  margin: 0px;
  font-size: 23px;
  font-weight: 600;
  margin-left: 40px;
`;

export const ImageContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 15px;
  @media only screen and (max-width: 1201px) {
  }
`;

export const BottomLogo = styled.img`
  width: 200px;
  margin: 0px 10px;
  @media only screen and (max-width: 1201px) {
    width: 100px;
  }
`;

export const AutomationHeader = styled.h2`
  margin: 0px;
  font-size: 25px;
  font-weight: 600;
  margin-left: 40px;
`;

export const AdvancedSettingButton = styled.strong`
  font-size: 15px;
  margin-left: 10px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
`;

export const ReportHeader = styled.h2`
  margin: 0px;
  font-size: 25px;
  font-weight: 600;
  margin-left: 40px;
`;
