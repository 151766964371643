import styled from "styled-components";
import FloorPlanIcon from "assets/img/floorplan.svg";
import FloorPlanIcon2 from "assets/img/floorplan2.svg";
import AutomationIcon from "assets/img/automation.svg";
import AutomationIcon2 from "assets/img/automation2.svg";
import ReportIcon from "assets/img/report.svg";
import ReportIcon2 from "assets/img/report2.svg";
import Profile from "components/Profile";
import { Location } from "components/Location/Location";
// import SettingIcon from 'assets/img/setting.svg';

const Container = styled.div`
  width: 330px;
  height: 100vh;
  overflow-y: scroll;
  /* background-color: #eeeeee; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  @media only screen and (max-width: 1201px) {
    width: 220px;
  }
`;

const HeaderBlock = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Clock = styled.p`
  font-size: 80px;
  margin: 0px;
  font-weight: 600;
  color: #454353;
  @media only screen and (max-width: 1201px) {
    font-size: 60px;
  }
`;

const DateString = styled.p`
  font-weight: 300;
  font-size: 16px;
  color: #454353;
  margin-top: -15px;
`;

const BodyBlock = styled.div`
  height: fit-content;
  width: 100%;
  padding: 0px 45px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1201px) {
    padding: 0px 25px;
  }
`;

const Tab = styled.div<any>`
  width: 100%;
  height: 55px;
  left: 50px;
  top: 311px;
  box-shadow: ${(props) => (props.active ? "1px 3px 20px rgba(196, 196, 196, 0.3)" : "none")};
  backdrop-filter: blur(20px);
  border-radius: 15px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  padding: 0px 20px;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.active ? "linear-gradient(331.89deg, #397CDD -52.72%, #93B4F9 406.63%)" : "transparent")};
  :hover {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
  }
`;

const TabText = styled.p<any>`
  font-size: 18px;
  margin: 0px;
  color: ${(props) => (props.active ? "white" : "#454353")};
  font-weight: 600;
  margin-left: 15px;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  @media only screen and (max-width: 1201px) {
    font-size: 14px;
  }
`;

const BottomBlock = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 1201px) {
    width: 220px;
    bottom: 0px !important;
    position: relative;
  }
`;

const Icon = styled.img`
  width: 20px;
`;

const DayMapper: any = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const Sidebar = ({ setToken, setTabs, tabs, roomDialog }: any) => {
  let today = new Date();
  var dateString =
    DayMapper[today.getDay()] +
    ", " +
    today.getUTCFullYear() +
    "/" +
    ("0" + (today.getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + today.getUTCDate()).slice(-2);
  var hour = String(today.getHours()).padStart(2, "0");
  var mins = String(today.getMinutes()).padStart(2, "0");

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to leave?")) {
      setToken();
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  const handleTabClick = (tab: string) => {
    roomDialog.onClose()
    setTabs(tab)
  };

  return (
    <>
      <Container>
        <div>
          <HeaderBlock>
            <Clock>{hour + ":" + mins}</Clock>
            <DateString>{dateString}</DateString>
          </HeaderBlock>
          <BodyBlock>
            <Tab onClick={() => handleTabClick("dashboard")} active={tabs === "dashboard"}>
              <Icon src={tabs === "dashboard" ? FloorPlanIcon : FloorPlanIcon2} />
              <TabText active={tabs === "dashboard"}>Dashboard</TabText>
            </Tab>
            {/* <Tab onClick={() => setTabs("automation")} active={tabs === "automation"}>
              <Icon src={tabs === "automation" ? AutomationIcon : AutomationIcon2} />
              <TabText active={tabs === "automation"}>Automation</TabText>
            </Tab> */}
            <Tab onClick={() => setTabs("report")} active={tabs === "report"}>
              <Icon src={tabs === "report" ? ReportIcon : ReportIcon2} />
              <TabText active={tabs === "report"}>Report</TabText>
            </Tab>
          </BodyBlock>
        </div>
        <BottomBlock>
          <Profile onClick={() => handleLogout()} />
        </BottomBlock>
      </Container>
    </>
  );
};
export default Sidebar;
