let iotApi = 'https://buildingapimgmt.azure-api.net/iot';
let utilApi = 'https://buildingapimgmt.azure-api.net/util';
let buildingApi = 'https://buildingapimgmt.azure-api.net/building';
let authApi = 'https://buildingapimgmt.azure-api.net/auth';
let energyApi = 'https://buildingapimgmt.azure-api.net/energy';

if (process.env.REACT_APP_ENV === "dev") {
  iotApi = 'http://localhost:8000';
  utilApi = 'http://localhost:8001';
  buildingApi = 'http://localhost:8002';
  authApi = 'http://localhost:8003';
  energyApi = 'http://localhost:8004';
}

const API_CONSTANTS = {
  // Auth API
  LOGIN_URL: authApi + '/api/v2.0/login',
  USER: authApi + '/api/v2.0/users',
  SIGNUP: authApi + '/api/v2.0/signup',

  // IOT API
  DEVICES: iotApi + '/api/v2.0/devices',
  C2D: iotApi + '/api/v2.0/c2d',
  DEVICES_CONTROL_URL: iotApi + '/api/v3.0/devicecontrol',
  HISTORY_DEVICE: iotApi + '/api/v2.0/historydevice',
  SELF_DRIVING: iotApi + '/api/v2.0/selfdriving',
  AUTOMATION: iotApi + '/api/v3.0/automations',
  DELETE_AUTOMATION: iotApi + '/api/v2.0/automations',

  // Energy API
  HISTORY_FLOOR: energyApi + '/api/v2.0/daikin_historyfloor',
  ENERGY_CONSUMPTION: energyApi + '/api/v2.0/energy_consumption',
  ENERGY_CONSUMPTION_V3: energyApi + '/api/v3.0/energy_consumption',
  DAIKIN_ENERGY_CONSUMPTION: energyApi + '/api/v2.0/daikin_energy_consumption',
  // TODO : Change to util endpoint
  REPORT: energyApi + '/api/v3.0/energy_report',

  // Util API
  BLOB: utilApi + '/api/v2.0/blob',
  NOTIFICATION: utilApi + '/api/v3.0/notification',

  // Building API
  FLOOR: buildingApi + '/api/v2.0/floors',
  // TODO : change this to new endpoint
  ROOMS: buildingApi + '/api/v2.0/rooms',
};

export { API_CONSTANTS };
