import React, { useState } from "react";
import { Login } from "./pages/login";
import { Home } from "./pages";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

function App() {
  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));

  if (!token) {
    return (
      <ToastProvider>
        <Login setToken={setToken} />
      </ToastProvider>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path="/">
          <ToastProvider>
            <Home setToken={setToken} />
          </ToastProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
